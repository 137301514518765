import $ from 'jquery';

import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.scss';

$(() => {
  $('[data-main-carousel]').slick({
    infinite: true,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          arrows: false,
        },
      },
    ],
  });

  const $galleries = $('[data-gallery]');

  $galleries.each((index, gallery) => {
    const galleryConfig = JSON.parse($(gallery).attr('data-gallery'));
    const $galleryThumbnails = $(gallery).next('[data-gallery-preview]');

    $(gallery).slick({
      infinite: true,
      dots: galleryConfig.dots,
      arrows: galleryConfig.arrows,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            arrows: false,
            centerMode: true,
          },
        },
      ],
    });

    if ($galleryThumbnails) {
      $galleryThumbnails.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '[data-gallery]',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: false,
        infinite: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 996,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 7,
            },
          },
        ],
      });
    }
  });
});

/* Menu toggle */
$(() => {
  const $primaryMenu = $('.primary');
  const $menuItems = $primaryMenu.find('.has-submenu');

  $menuItems.on('click', (e) => {
    const $self = $(e.target);
    const $current = $self.parent().parent('.has-submenu');
    const $currentArrow = $current.children('.nav-toggle-arrow');

    $current.toggleClass('open');

    $menuItems
      .not($current)
      .removeClass('open')
      .children('.drop-down')
      .slideUp('slow');

    const $submenu = $current.children('.drop-down');
    $submenu.slideToggle('slow');

    $currentArrow.toggleClass('arrow-active');
    $menuItems
      .not($current)
      .children('.nav-toggle-arrow')
      .removeClass('arrow-active');

    //$self.addClass('title-bold');
    //$menuItems.not($current).children('.nav-title').removeClass('title-bold');
  });
});

/* Close menu when mouse leaves it. */
$(() => {
  $('.primary')
    .find('> .nav-li .drop-down')
    .mouseleave((e) => {
      const self = $(e.currentTarget.parentElement);

      /* Slide on menu */
      $('.drop-down')
        .not(self.children('.drop-down'))
        .slideUp('slow', () => {
          self.children('.drop-down').slideUp('slow');
        });

      /* Active menu bold title */
      self.children('.nav-title').removeClass('title-bold');
      $('.nav-li .nav-title')
        .not(self.children('.nav-title'))
        .removeClass('title-bold');

      /* Arrow rotating */
      $('.nav-li .nav-toggle-arrow')
        .not(self.children('.nav-toggle-arrow'))
        .removeClass('arrow-active');
      self.children('.nav-toggle-arrow').removeClass('arrow-active');

      /* Opacity mobile */
      $('.nav-li').addClass('opacity');
      self.removeClass('opacity');

      $('.nav-li .nav-toggle-arrow').addClass('opacity');
      self.children('.nav-toggle-arrow').removeClass('opacity');

      if (!$('.nav-toggle-arrow').hasClass('arrow-active')) {
        $('.nav-li').removeClass('opacity');
        $('.nav-toggle-arrow').removeClass('opacity');
      }
    });
});

/* Header search */
$(() => {
  const jQuery = $;
  $('.header-submit').click(($event) => {
    const term = jQuery($event.currentTarget)
      .parent()
      .find('.header-search-input')
      .val();

    /* Avoid search with an empty search */
    if (term.length === 0) {
      $event.preventDefault();
    }
  });
});

/* Mobile menu button toggle */
$(() => {
  $('.nav-menu-btn').click(() => {
    $('.nav-menu-btn').toggleClass('open');
    $('.navigation-login-row').slideToggle('slow');
  });
});

/* Mobile search button toggle */
$(() => {
  $('.btn-header-search').click(() => {
    $('.header-form-search').slideToggle('slow');
  });
});

/* Accordion toggle */
$(() => {
  $('[data-accordion]')
    .find('[data-accordion-heading]')
    .click((e) => {
      const self = $(e.currentTarget);

      /* Slide on text */
      self.toggleClass('is-active');
      self.next().slideToggle('slow').toggleClass('is-active');
      $('.accordion-content').not(self.next()).slideUp('slow');

      /* Arrow rotating */
      $('.accordion-arrow')
        .not(self.find('.accordion-arrow'))
        .removeClass('arrow-active');
      self.find('.accordion-arrow').toggleClass('arrow-active');
    });
});

/* Tab animation */
$(document).ready(() => {
  $('[data-id=tab-1]').addClass('tab-color');
  $('#tab-1').addClass('is-active');

  $('.tabs-heading button').click((e) => {
    const self = $(e.currentTarget);
    const selfTab = self.data('id');

    $('.tabs-heading button').not(self).removeClass('tab-color');
    self.addClass('tab-color');

    $('.tabs-text').removeClass('is-active');
    $(`#${selfTab}`).addClass('is-active');
  });
});

window.USER_IS_TOUCHING = false;
window.addEventListener('touchstart', function onFirstTouch() {
  window.USER_IS_TOUCHING = true;
}, false);

/* Copy Url Button */
$(() => {
  const tooltip = document.querySelector('.tooltipText');
  const copyButton = $('.copy-url-btn');
  const brandImagesSlide = $('.brandimages-brand');
  const brandImages = $('.brandimages');

  copyButton.on('click', () => {
    const copyText = document.querySelector('#post-url');

    copyText.select();
    document.execCommand('copy');
    tooltip.innerHTML = 'Copied';
  });

  copyButton.on('onmouseout', () => {
    tooltip.innerHTML = 'Copy';
  });

  /**
   * Brandimages
   */
  brandImagesSlide.on('click', (e) => {
    const currentSlide = $(e.currentTarget);

    if (currentSlide.hasClass('active')) {
      closeAllSlides();
    } else {
      changeSlideStatus(e);
    }
  });

  brandImagesSlide.hover((e) => {
    if (!window.USER_IS_TOUCHING) {
      changeSlideStatus(e);
    }
  });

  brandImages.mouseleave(() => {
    currentSlideSmall();
  });

  $(document).mouseup(function (e) {
    const container = brandImages;

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      closeAllSlides();
    }
  });

  function changeSlideStatus(e) {
    currentSlideSmall();
    $(e.currentTarget).removeClass('small').addClass('active');
  }

  function closeAllSlides() {
    brandImagesSlide.removeClass('active').removeClass('small');
  }

  function currentSlideSmall() {
    brandImagesSlide.removeClass('active').addClass('small');
  }
});

$(() => {
  let itemsMainDiv = '.MultiCarousel';
  let itemsDiv = '.MultiCarousel-inner';
  let itemWidth = '';
  $('.leftLst, .rightLst').click(() => {
    let condition = $(this).hasClass('leftLst');
    if (condition) {
      click(0, this);
    } else {
      click(1, this);
    }
  });
  ResCarouselSize();
  $(window).resize(function () {
    ResCarouselSize();
  });

  function ResCarouselSize() {
    let incno = 0;
    let dataItems = 'data-items';
    let itemClass = '.item';
    let id = 0;
    let btnParentSb = '';
    let itemsSplit = '';
    let sampwidth = $(itemsMainDiv).width();
    let bodyWidth = $('body').width();
    $(itemsDiv).each(() => {
      id = id + 1;
      let itemNumbers = $(this).find(itemClass).length;
      btnParentSb = $(this).parent().attr(dataItems);
      itemsSplit = btnParentSb.split(',');
      $(this)
        .parent()
        .attr('id', 'MultiCarousel' + id);
      if (bodyWidth >= 1200) {
        incno = itemsSplit[3];
        itemWidth = sampwidth / incno;
      } else if (bodyWidth >= 992) {
        incno = itemsSplit[2];
        itemWidth = sampwidth / incno;
      } else if (bodyWidth >= 768) {
        incno = itemsSplit[1];
        itemWidth = sampwidth / incno;
      } else {
        incno = itemsSplit[0];
        itemWidth = sampwidth / incno;
      }
      $(this).css({
        transform: 'translateX(0px)',
        width: itemWidth * itemNumbers,
      });
      $(this)
        .find(itemClass)
        .each(function () {
          $(this).outerWidth(itemWidth);
        });
      $('.leftLst').addClass('over');
      $('.rightLst').removeClass('over');
    });
  }

  function ResCarousel(e, el, s) {
    let leftBtn = '.leftLst';
    let rightBtn = '.rightLst';
    let translateXval = '';
    let divStyle = $(el + ' ' + itemsDiv).css('transform');
    let values = divStyle.match(/-?[\d\.]+/g);
    let xds = Math.abs(values[4]);
    if (e === 0) {
      translateXval = parseInt(xds) - parseInt(itemWidth * s);
      $(el + ' ' + rightBtn).removeClass('over');

      if (translateXval <= itemWidth / 2) {
        translateXval = 0;
        $(el + ' ' + leftBtn).addClass('over');
      }
    } else if (e === 1) {
      let itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
      translateXval = parseInt(xds) + parseInt(itemWidth * s);
      $(el + ' ' + leftBtn).removeClass('over');

      if (translateXval >= itemsCondition - itemWidth / 2) {
        translateXval = itemsCondition;
        $(el + ' ' + rightBtn).addClass('over');
      }
    }
    $(el + ' ' + itemsDiv).css(
      'transform',
      'translateX(' + -translateXval + 'px)'
    );
  }

  function click(ell, ee) {
    let Parent = '#' + $(ee).parent().attr('id');
    let slide = $(Parent).attr('data-slide');
    ResCarousel(ell, Parent, slide);
  }
});
